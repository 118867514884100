
<template>
  <div class="news-user-index width-100">
    <el-row
      v-if="tablesStatus.news.rows.length"
      v-loading="loading"
      type="flex"
      justify="center"
      align="middle"
    >
      <el-col
        :span="22"
      >
        <el-row
          :gutter="20"
          type="flex"
          class="cards"
        >
          <el-col
            v-for="(news ,index) in tablesStatus.news.rows"
            :key="index"
            class="cards__item"
            :span="8"
          >
            <el-card
              class="card"
              :body-style="{ padding: '0px' }"
            >
              <div
                @click="$router.push({name: 'newsUserDetail', params: { newsId: news.id } })"
              >
                <div class="card__image-box">
                  <el-image
                    class="card__image"
                    :src="news.image"
                    fit="cover"
                  >
                    <div
                      slot="error"
                      class="image-slot"
                    />
                  </el-image>
                </div>
                <div class="card__infos">
                  <span class="card__date">{{ news.publishedAt | date('DD MMMM YYYY') }}</span>
                  <h2 class="card__title">
                    {{ news.title }}
                  </h2>
                  <p class="card__abstract">
                    {{ news.abstract }}
                  </p>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <div class="pagination">
          <el-row
            class="pagination__row"
            :gutter="20"
          >
            <el-col :span="24">
              <el-pagination
                class="pagination__controls"
                :current-page="tablesStatus.news.currentPage"
                :page-sizes="[6, 12, 60, 120]"
                :page-size="tablesStatus.news.perPage"
                background
                layout="sizes, prev, pager, next"
                :total="tablesStatus.news.count"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              />
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <span v-else>
      {{ $t('news.index.noRecords') }}
    </span>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import fileService from '@/lib/file';
import { newsApi } from '@/api';

export default {
  name: 'NewsIndexUser',
  components: {
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters([
      'filtersStatus',
      'tableFilters',
      'tablesStatus',
    ]),
  },
  created() {
    this.updateTablePerPage({ tableKey: 'news', perPage: 6 });
    this.parseQueryStringStatus(this.$route.query);
    this.tableRows();
  },
  beforeRouteUpdate(to, from, next) {
    this.parseQueryStringStatus(to.query);
    this.tableRows();
    next();
  },
  methods: {
    ...mapActions([
      'updateTableRows',
      'updateTableCount',
      'updateTablePerPage',
      'parseQueryStringStatus',
    ]),
    async tableRows() {
      try {
        this.loading = true;
        const indexResponse = await newsApi.publishedIndex(this.tableFilters('news'));
        const countResponse = await newsApi.publishedCount(this.tableFilters('news'));
        this.updateTableRows({
          tableKey: 'news',
          rows: indexResponse.data.map(item => ({
            ...item,
            image: item.image !== '' ? fileService.base64ImagetoDataUri(item.image) : '',
          })),
        });
        this.updateTableCount({ tableKey: 'news', count: countResponse.data.count });
      } catch (error) {
        throw error;
      } finally {
        this.loading = false;
      }
    },
    handleSizeChange(perPage) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          tableKey: 'news',
          perPage,
          page: 1,
        },
      }).catch(() => {});
    },
    handleCurrentChange(page) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          tableKey: 'news',
          page,
        },
      }).catch(() => {});
    },
  },
};
</script>

<style lang="scss">

.news-user-index {

  .cards {
    flex-wrap: wrap;
    margin-top: 2rem;

    .cards__item {
      display: flex;
      flex-direction: column;
    }

    $main-block: '.card';

    #{$main-block} {
      flex: 1;
      margin-bottom: 1rem;
      cursor: pointer;

      #{$main-block}__image-box {
        width: 100%;
        position: relative;
        padding-top: 60%;
        #{$main-block}__image {
          position: absolute;
          top: 0px;
          bottom: 0px;
          left: 0px;
          right: 0px;
          background-color: $--collapse-header-background-color;
          display: flex;
          justify-content: center;
          align-items: center;
          pointer-events: none;
        }
      }
      #{$main-block}__infos {
        padding: 1rem;
        #{$main-block}__date {
          font-size: $--font-size-small;
        }
        #{$main-block}__title {
          margin-top: 0.25rem;
          font-weight: bold;
          font-size: $--font-size-extra-large;
        }
        #{$main-block}__abstract {
          margin-top: 0.8rem;
          min-height: 80px;
        }
      }
    }

  }
  .pagination {

    .pagination__row {
      margin-top: 1rem;

      .pagination__controls {
        display: flex;
        justify-content: flex-end;
      }
    }

  }
}


</style>
